import { useMemo } from "react";

import { createAvatar, StyleOptions } from '@dicebear/core';
import { pixelArt, initials, glass } from '@dicebear/collection';
import { AnyObject } from "@/utils/types";

const COLLECTIONS = {
  "pixel-art": pixelArt,
  initials: initials,
  glass: glass,
};

const COLLECTION_OPTIONS: Record<string, AnyObject> = {
  initials: {
    // backgroundColor: [],
    // textColor: ["#000000"],
    radius: 50,
  },
  glass: {
    radius: 50,
  },
};

export function DiceBearAvatar({
  seed,
  collection = "initials",
  options,
}: {
  seed: string,
  collection?: keyof typeof COLLECTIONS,
  options?: StyleOptions<typeof COLLECTIONS[typeof collection]>,
}) {
  const avatar = useMemo(() => {
    return createAvatar(COLLECTIONS[collection], {
      seed,
      size: 32,
      ...(COLLECTION_OPTIONS[collection] ?? {}),
      ...options,
    }).toDataUri();
  }, [seed, collection, options]);

  return <img src={avatar} alt="Avatar" />;
}
