import { Country } from './types/common';
import { ArchivationStatusTypes, CMRType, CMRTypeData } from './types/shipment';
import { getFilterYears } from './utils/date';

export const SEARCH_DEBOUNCED_TIME = 500;
export const SEARCH_LIMIT = 10;
export const SHOWN_PROP_NUM = 5;

export const REQUIRED_FIELD_KEY = 'required';

// carbone.io document ids
export const CMR_DOCUMENT_ID = 'CMR_DOCUMENT_ID';
export const AD_HOC_CUSTOMS_DOCUMENT_ID =
  '245fd3e332afb012d53b493264df9a20edb080de339b494c75fd4f2c35de63be';
export const PERMANENT_CUSTOMS_DOCUMENT_ID =
  '6abf047d62f5a96265e6652372b2bc2c4647f25fa535efea0bdb7964ccc3ce35';
export const MTC_DOCUMENT_ID =
  '7822dabb418101ae40361d477640f569e362f9735ee8d89d0864b15f04f083f1';
export const SHIPMENT_SUMMARY_CUSTOMS_DOCUMENT_ID =
  'ae3300d12330a90a8eb5cd71c81d9f8e120ab284772d61e66a8eaee6d2d91d89';
export const VAT_DECLARATION_DOCUMENT_ID =
  '5ca3e608e6edb43f02b38a8a7467d6c00c8e7c3d9a348958cb7ac08f64bfa8f9';
export const TRANSPORT_COST_ID =
  'b56ea17a62bce17d0b0a81edf0574dba6f9e7166c1f188cc40ddfc560b08dc66';
export const SINGLE_SHIPMENT_EXPORT_DOCUMENT_ID =
  '288aea054e5e730c7e6f1773d12fc2448668360ab114258f1b9cc2089cb3f1f7';
export const VIES_CONFIRMATION_DOCUMENT_ID =
  'a146670f87584e8a1f063e46477216c81a0f2455c5e59894cda8fca62851600a';
export const HIDE_TRANSPORT_COST_INCOTERMS = [
  'CPT',
  'CIP',
  'DAP',
  'DPU',
  'DDP',
];

export const ZIP_DOWNLOAD_DOCUMENT_IDS = [
  'AD_HOC_CUSTOMS_DOCUMENT_ID',
  'MTC_DOCUMENT_ID',
  'TRANSPORT_COST_ID',
  'VIES_CONFIRMATION_DOCUMENT_ID',
  'CMR_DOCUMENT_ID',
];

// TODO: these are test values, replace them with real calculations
export const CBAM_RATIO = 1.97;
export const CBAM_PRICE = 12.5;
export const DE_MINIMIS_TRHESHOLD = 150;
export const DIRECT_RATIO = 0.7;

export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

export const CMR_LETTER_TRUNCATED_LENGTH = 52;

export const DASHBOARD_DEFAULT_PARAMS = {
  _limit: 10,
  _sort: 'created',
  _order: 'desc',
};

export const SHIPMENTS_LIST_DEFAULT_PARAMS = {
  _limit: 2000,
  _sort: 'created',
  _order: 'desc',
};

export const BASE_RESPONSE = {
  count: 0,
  data: [],
};

export const CMR_PRODUCTS_MAX_COUNT = 8;

export const COUNTRIES: Country[] = [
  {
    value: 'austria',
    title: {
      'en-US': 'Austria',
      'hu-HU': 'Ausztria',
    },
  },
  {
    value: 'hungary',
    title: {
      'en-US': 'Hungary',
      'hu-HU': 'Magyarország',
    },
  },
  {
    value: 'serbia',
    title: {
      'en-US': 'Serbia',
      'hu-HU': 'Szerbia',
    },
  },
  {
    value: 'slovakia',
    title: {
      'en-US': 'Slovakia',
      'hu-HU': 'Szlovákia',
    },
  },
  {
    value: 'slovenia',
    title: {
      'en-US': 'Slovenia',
      'hu-HU': 'Szlovénia',
    },
  },
];

export const CMRTypeDataByCMRType: Record<CMRType, CMRTypeData> = {
  consignor: {
    number: '1',
    titles: [
      'A feladó példánya',
      'Specimen for consignor',
      'Exemplar für Absender',
    ],
    color: '#ED1848',
  },
  consignee: {
    number: '2',
    titles: [
      'A címzett példánya',
      'Specimen for consignee',
      'Exemplar für Empfänger',
    ],
    color: '#0182C7',
  },
  carrier: {
    number: '3',
    titles: [
      'A szállító példánya',
      'Specimen for carrier',
      'Exemplar für Lieferanten',
    ],
    color: '#00A74F',
  },
};

export const ZALAVAM = {
  name: 'Zalavám Kft',
  phone: '+36 30 203 9236',
  email: 'vamzoll@vamzoll.hu',
  eori: 'HU0018866379',
  euTaxNumber: 'HU11691541',
  financialInformation: {
    bankName: 'OTP Bank - Hungary OTPVHUHB',
    bankAccount: '11763495-54243880-00000000',
  },
  address: {
    city: 'Nagykanizsa',
    address: 'Fő út 7.',
    zip: '8800',
    country: 'Hungary',
    counties: [
      {
        name: 'Zala',
        city: 'Zalaegerszeg',
      },
      {
        name: 'Csongrád-Csanád',
        city: 'Szeged',
      },
      {
        name: 'Bács-Kiskun',
        city: 'Kecskemét',
      },
    ],
  },
  reps: [
    {
      name: 'Viziné Varga Márta EV.',
      place: 'Szeged',
    },
    {
      name: 'Globallog',
      place: 'Szeged',
    },
  ],
};

export const YEARS = getFilterYears();

export const QUARTERS: Record<string, number> = {
  Q1: 1,
  Q2: 2,
  Q3: 3,
  Q4: 4,
} as const;

export const MONTHS: Record<string, number> = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
} as const;

export const SHIPMENT_ARCHIVATION_FILTERS: Record<
  ArchivationStatusTypes,
  string
> = {
  all: 'all',
  archived: 'archived',
  'not-archived': 'not-archived',
} as const;

export const incoterms = [
  'exw',
  'fca',
  'fas',
  'fob',
  'cpt',
  'cip',
  'cfr',
  'cif',
  'dap',
  'dpu',
  'ddp',
];

export const documents = [
  'invoice',
  'transport-invoice',
  'eur1',
  'cmr',
  'way-of-goods',
  'customs-clearance-resolution',
  'signed-eu-invoice',
  'signed-cmr',
  'signed-ad-hoc-authentication',
  'signed-vies',
];
