import { useMemo } from "react"
import { useLocation } from 'react-router-dom';

import {
  AudioWaveform,
  BookOpen,
  Bot,
  Command,
  Frame,
  GalleryVerticalEnd,
  Map,
  PieChart,
  Settings2,
  SquareTerminal,
} from "lucide-react"

import { NavMain } from "@/components/nav-main"
import { NavProjects } from "@/components/nav-projects"
import { NavUser } from "@/components/nav-user"
import { TeamSwitcher } from "@/components/team-switcher"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar"

import SidebarIcon from './SidebarIcon';
import { useTranslation } from '@/hooks/useTranslation';
import { useClientStore } from '@/stores/client';
import { useAuthContext } from '@/hooks/useAuth';
import { SidebarItem } from './SidebarItem';
import { MenuItem } from '@/types/menus';
import { useMenus } from '@/hooks/useMenus';
import { getMenuIcon } from "./layout/MenuIcon";

// This is sample data.
const data = {
  navMain: [
    {
      title: "Playground",
      url: "#",
      icon: SquareTerminal,
      isActive: true,
      items: [
        {
          title: "History",
          url: "#",
        },
        {
          title: "Starred",
          url: "#",
        },
        {
          title: "Settings",
          url: "#",
        },
      ],
    },
    {
      title: "Models",
      url: "#",
      icon: Bot,
      items: [
        {
          title: "Genesis",
          url: "#",
        },
        {
          title: "Explorer",
          url: "#",
        },
        {
          title: "Quantum",
          url: "#",
        },
      ],
    },
    {
      title: "Documentation",
      url: "#",
      icon: BookOpen,
      items: [
        {
          title: "Introduction",
          url: "#",
        },
        {
          title: "Get Started",
          url: "#",
        },
        {
          title: "Tutorials",
          url: "#",
        },
        {
          title: "Changelog",
          url: "#",
        },
      ],
    },
    {
      title: "Settings",
      url: "#",
      icon: Settings2,
      items: [
        {
          title: "General",
          url: "#",
        },
        {
          title: "Team",
          url: "#",
        },
        {
          title: "Billing",
          url: "#",
        },
        {
          title: "Limits",
          url: "#",
        },
      ],
    },
  ],
  projects: [
    {
      name: "Design Engineering",
      url: "#",
      icon: Frame,
    },
    {
      name: "Sales & Marketing",
      url: "#",
      icon: PieChart,
    },
    {
      name: "Travel",
      url: "#",
      icon: Map,
    },
  ],
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const auth = useAuthContext();
  const { t, i18n } = useTranslation();
  const { createNavigation } = useMenus();
  const location = useLocation();
  const { pathname } = location;

  const language = useMemo(
    () => i18n.resolvedLanguage ?? i18n.language,
    [i18n.resolvedLanguage, i18n.language]
  );

  const client = useClientStore((state) => state.client);
  const setMenu = useClientStore((state) => state.setMenu);
  const availableClients = useClientStore((state) => state.availableClients);

  const menuItems = useMemo(() => {
    const clientMenus = client?.menus ?? [];
    const newMenuItems: MenuItem[] = [];

    for (const menu of clientMenus) {
      const title = menu.definition?.title?.[language] ?? menu.title;
      const plural = menu.definition?.plural?.[language] ?? menu.title;

      const menuData: MenuItem = {
        name: plural,
        icon: getMenuIcon({ icon: menu.icon }),
      };

      if (menu.createEnabled) {
        menuData.basePath = `/resources/${menu.definitionName}`;
        menuData.children = [
          {
            name: t('common:menu.create', { resource: title.toLowerCase() }),
            href: `/resources/${menu.definitionName}/create`,
          },
          {
            name: t('common:menu.list', { resource: plural.toLowerCase() }),
            href: `/resources/${menu.definitionName}`,
          },
        ];
      } else {
        menuData.href = `/resources/${menu.definitionName}`;
      }

      newMenuItems.push(menuData);
    }

    const menu = createNavigation(
      newMenuItems,
      client?.modules || [],
      auth.user.role
    );

    setMenu(menu);

    return menu;
  }, [
    client?.menus,
    client?.modules,
    createNavigation,
    auth.user.role,
    setMenu,
    language,
    t,
  ]);

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher teams={availableClients} />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={menuItems} />
        {/* <NavProjects projects={data.projects} /> */}
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={auth.user} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
