import { BellIcon } from "lucide-react";

export function Bell({ count }: { count: number }) {
  return (
    <div className='flex relative'>
      <BellIcon className="h-5 w-5" />
      {
        count > 0 && (
          <div className='absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white rounded-full text-xs px-1 min-w-4'>
            {
              count > 10 ? '!' : count
            }
          </div>
        )
      }
    </div>
  );
}