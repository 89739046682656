import { useCallback } from "react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  // DropdownMenuSeparator,
  // DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { ClientType } from "@/schemas/client"
import { ScrollArea } from "./ui/scroll-area"
import { ClientTypeWithRole, useClientStore } from "@/stores/client"
import { DiceBearAvatar } from "./DiceBearAvatar"

export function TeamSwitcher({
  teams,
}: {
  teams: ClientType[]
}) {
  const { isMobile } = useSidebar()

  const setClient = useClientStore((state) => state.setClient);
  const selectedClient = useClientStore((state) => state.client);

  const handleChange = useCallback(
    (client: ClientTypeWithRole) => {
      setClient(client);

      // TODO: replace with react-router navigate when we clean the state handling
      window.location.replace('/');
    },
    [setClient]
  );

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex aspect-square size-8 items-center justify-center rounded-lg text-sidebar-primary-foreground">
                <DiceBearAvatar
                  seed={selectedClient?.name ?? 'unknown'}
                  collection="glass"
                />
                {/* <activeTeam.logo className="size-4" /> */}
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {selectedClient?.name}
                </span>
                {/* <span className="truncate text-xs">{activeTeam.plan}</span> */}
              </div>
              <CaretSortIcon className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Clients
            </DropdownMenuLabel>
            <ScrollArea className="h-72">
              {teams.map((team) => (
                <DropdownMenuItem
                  key={team.name}
                  onClick={() => handleChange(team)}
                  className="gap-2 p-2"
                >
                  <div className="flex size-6 items-center justify-center">
                    <DiceBearAvatar
                      seed={team.name}
                      collection="glass"
                    />
                  </div>
                  {team.name}
                  {/* <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut> */}
                </DropdownMenuItem>
              ))}
            </ScrollArea>
            {/* <DropdownMenuSeparator /> */}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
